//colors
$color_0: #dedecb;
$color_1: #000;
$color_2: #fff;
$color_3: rgba(0, 0, 0, 0.42);
$color_4: #cb6077;
$color_5: #000000;
$color_6: #3b3228;
$color_7: #343434;
$color_8: #4e4e4e;
$color_9: #1b1b1b;
$color_10: #4d4d4b;
$color_11: #f5f5f5;
$color_12: #efefef;
$color_13: #8ab3b5;
$color_14: #222222;
$color_15: #555;
$color_16: #fcfcfc;
$color_17: #ccc;
$color_18: #bbb;
$color_link: #6d0000;

//fonts
$font1: PT Serif, serif;
$font2: PT Mono;
