@media print {
	.home {
		width: 60%;
		margin-top: 2em;
	}
	.nav-home {
		top: 2em;
		width: 60%;
	}
	.wrapper {
		width: 100%;
		border: 0;
		margin: 0;
	}
	.article {
    		padding: 1em 3.5em 1.25em;
	}
	.toc,
	.nav, 
	.footer {
		display: none;
	}
	.goup-container {
		display: none !important;
	}
}
