.highlight {
	background: #ffffff;
	border: 1px solid #000;
	margin-left: auto;
	margin-right: auto;
	pre {
		position: relative;
		margin: 0;
		padding: 1em;
	}
	.c {
		color: #999988;
		font-style: italic;
	}
	.err {
		color: #a61717;
		background-color: #e3d2d2;
	}
	.k {
		font-weight: bold;
	}
	.o {
		font-weight: bold;
	}
	.cm {
		color: #999988;
		font-style: italic;
	}
	.cp {
		color: #999999;
		font-weight: bold;
	}
	.c1 {
		color: #999988;
		font-style: italic;
	}
	.cs {
		color: #999999;
		font-weight: bold;
		font-style: italic;
	}
	.gd {
		color: #000000;
		background-color: #ffdddd;
		.x {
			color: #000000;
			background-color: #ffaaaa;
		}
	}
	.ge {
		font-style: italic;
	}
	.gr {
		color: #aa0000;
	}
	.gh {
		color: #999999;
	}
	.gi {
		color: #000000;
		background-color: #ddffdd;
		.x {
			color: #000000;
			background-color: #aaffaa;
		}
	}
	.go {
		color: #888888;
	}
	.gp {
		color: #555555;
	}
	.gs {
		font-weight: bold;
	}
	.gu {
		color: #aaaaaa;
	}
	.gt {
		color: #aa0000;
	}
	.kc {
		font-weight: bold;
	}
	.kd {
		font-weight: bold;
	}
	.kp {
		font-weight: bold;
	}
	.kr {
		font-weight: bold;
	}
	.kt {
		color: #445588;
		font-weight: bold;
	}
	.m {
		color: #009999;
	}
	.s {
		color: #d14;
	}
	.na {
		color: #008080;
	}
	.nb {
		color: #0086b3;
	}
	.nc {
		color: #445588;
		font-weight: bold;
	}
	.no {
		color: #008080;
	}
	.ni {
		color: #800080;
	}
	.ne {
		color: #990000;
		font-weight: bold;
	}
	.nf {
		color: #990000;
		font-weight: bold;
	}
	.nn {
		color: #555555;
	}
	.nt {
		color: #000080;
	}
	.nv {
		color: #008080;
	}
	.ow {
		font-weight: bold;
	}
	.w {
		color: #bbbbbb;
	}
	.mf {
		color: #009999;
	}
	.mh {
		color: #009999;
	}
	.mi {
		color: #009999;
	}
	.mo {
		color: #009999;
	}
	.sb {
		color: #d14;
	}
	.sc {
		color: #d14;
	}
	.sd {
		color: #d14;
	}
	.s2 {
		color: #d14;
	}
	.se {
		color: #d14;
	}
	.sh {
		color: #d14;
	}
	.si {
		color: #d14;
	}
	.sx {
		color: #d14;
	}
	.sr {
		color: #009926;
	}
	.s1 {
		color: #d14;
	}
	.ss {
		color: #990073;
	}
	.bp {
		color: #999999;
	}
	.vc {
		color: #008080;
	}
	.vg {
		color: #008080;
	}
	.vi {
		color: #008080;
	}
	.il {
		color: #009999;
	}
	.lineno {
		-webkit-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
	}
}
.lineno::-moz-selection {
	background-color: transparent;
}
.lineno::selection {
	background-color: transparent;
}
pre.highlight {
	padding: 1em;
	line-height: 1.5;
	color: #333332;
	margin-bottom: 1.5em;
}
